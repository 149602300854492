import { useIntl } from "gatsby-plugin-intl"
import React, { useEffect } from "react"
import { PlayerContext } from "../components/audioPlayerWrapper"
import Seo from "../components/seo"
import "./radio.scss"

export default () => {
  const { setHidePlayer, togglePause } = React.useContext(PlayerContext)
  const intl = useIntl()
  useEffect(() => {
    setHidePlayer(true)
    togglePause(true)
  }, [1])
  return (
    <main className="hem-radio">
      <Seo title={"Radio"} />
      <iframe src={`https://radio.hem.fm/public/hem?locale=${intl.locale}`} />
      <div className="radio-footer">
        <div className="footer-slide">
          <img
            src={require("../images/footer-marquee.svg").default}
            alt="HEM Radio"
          />
          <img
            src={require("../images/footer-marquee.svg").default}
            alt="HEM Radio"
          />
          <img
            src={require("../images/footer-marquee.svg").default}
            alt="HEM Radio"
          />
          <img
            src={require("../images/footer-marquee.svg").default}
            alt="HEM Radio"
          />
        </div>
      </div>
    </main>
  )
}
